<template>
  <section class="my-6">
    <v-card class="scoreTables">
      <div class="card-header-padding">
        <div class="d-flex align-center">
          <div>
            <h5 class="font-weight-bold text-h5 text-typo mb-0">
              {{ title }}
            </h5>
            <p class="text-sm text-body mb-0">
              {{ description }}
            </p>
          </div>
        </div>
      </div>
      <v-card-title class="mb-4">
        <v-row>
          <!-- <v-col cols="6" v-if="departments">
              <v-select
                return-object
                :items="departments"
                item-text="name"
                item-value="id"
                v-model="filterSelectedDepartment"
                label="ЗАН сонгоx"
              >
              </v-select>
            </v-col> -->
        </v-row>
        <v-checkbox
          v-model="showEelj1"
          label="1-Р ЭЭЛЖ"
          hide-details
        ></v-checkbox>
        <v-checkbox
          class="ml-4"
          v-model="showEelj2"
          color="yellow"
          label="2-Р ЭЭЛЖ"
          hide-details
        ></v-checkbox>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Xайx"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-data-table
        v-model="selected"
        :headers="headers2"
        :items="teachers2"
        :search="search"
        hide-details
        hide-default-footer
        hide-default-header
        :items-per-page="-1"
      >
        <template v-slot:header="{ props: {} }">
          <thead>
            <tr>
              <th rowspan="2" style="1%">No.</th>
              <th rowspan="2" style="2%">Name</th>
              <th rowspan="2">SS</th>
              <th
                :style="
                  h.eeljIndex == 1
                    ? 'background: #ececec'
                    : 'background: #F7F589'
                "
                colspan="7"
                class="text-center"
                v-for="(h, hIndex) in weekDays2"
                :key="hIndex"
              >
                <span :title="h"
                  >{{ h.name.toUpperCase() }} {{ +h.eeljIndex }}-Р ЭЭЛЖ</span
                >
              </th>
            </tr>
            <tr>
              <th v-for="(tsag, tsagIndex) in headerNumbers" :key="tsagIndex">
                {{ tsag.tsag }}
              </th>
            </tr>
          </thead>
        </template>

        <template slot="item" slot-scope="props">
          <tr @click="showAlert(props.item)">
            <td>{{ props.item.index }}</td>
            <td class="px-4">{{ props.item.name2 }}</td>
            <td v-if="props.item.lessons && props.item.lessons.length > 0">
              {{ props.item.lessons.length }}
            </td>
            <td v-else>-</td>
            <td
              style="width: 50px"
              v-for="(cellNumber, tsagIndex) in allCells"
              :key="'td' + tsagIndex"
              @click="_clickCell(props.item, cellNumber)"
            >
              <!-- <span
                v-if="_getTeacherLesson(props.item, cellNumber)"
                style="font-size: 8pt; font-weight: bold"
                >{{ _getTeacherLesson(props.item, cellNumber).departmentName
                }}{{
                  _getTeacherLesson(props.item, cellNumber).classGroup.name
                }}-{{ cellNumber }}</span
              > <span v-else style="background: yellow">{{ cellNumber }}</span> -->
              <span v-if="props.item.lessons && props.item.lessons[cellNumber]">
                {{ props.item.lessons[cellNumber].departmentName
                }}{{ props.item.lessons[cellNumber].classGroup.name }}</span
              >
              <span v-else></span>
              <!-- <span v-else>{{ cellNumber }}</span> -->
            </td>

            <!-- <template>
              <v-menu
                min-width="100"
                bottom
                offset-x
                offset-y
                v-for="(tsag, tsagIndex) in 70"
                :key="'td' + tsagIndex"
              >
                <template v-slot:activator="{ on }">
                  <td v-on="on">
                    {{ tsag }}
                  </td>
                </template>
                <v-list>
                  <v-list-item-group
                    v-model="departmentSelected"
                    active-class="departmentSelected"
                    color="green"
                  >
                    <v-list-item
                      v-for="department in departments"
                      :key="department.id"
                    >
                      <v-menu offset-y min-width="100">
                        <template v-slot:activator="{ on }">
                          <v-list-item-content>
                            <v-list-item-title v-on="on">{{
                              department.name
                            }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                        <v-list>
                          <v-list-item
                            v-for="(
                              classGroup, cgIndex
                            ) in department.classGroups"
                            :key="classGroup.id + '-' + cgIndex"
                          >
                            <v-list-item-title
                              @click="
                                _clickCell(
                                  props.item,
                                  tsag,
                                  department,
                                  classGroup
                                )
                              "
                              >{{ department.name
                              }}{{ classGroup.name }}</v-list-item-title
                            >
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </template> -->

            <td></td>
            <!-- <td>
                <v-icon small class="mr-2" @click.stop="_editItem(props.item)">
                  mdi-pencil
                </v-icon>
              </td> -->
          </tr>
        </template>

        <v-alert slot="no-results" :value="true" color="error" icon="warning">
          Your search for "{{ search }}" found no results.
        </v-alert>
      </v-data-table>
    </v-card>

    <v-dialog v-model="selectClassGroupDialog" max-width="400">
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title
          class="pt-0 text-h6 text-typo justify-center"
          v-if="departments"
          >Ангиа сонгоно уу
        </v-card-title>
        <h2 v-if="selectedTeacher">{{ selectedTeacher.firstName }}</h2>
        <v-card-actions>
          <v-row>
            <v-col cols="6"
              ><v-select
                v-if="departments"
                return-object
                v-model="selectedDepartment"
                :items="departments"
                item-text="name"
                item-value="id"
                label="Ангиа сонгоx"
              ></v-select
            ></v-col>
            <v-col cols="6">
              <v-select
                v-if="selectedDepartment"
                return-object
                v-model="selectedClassGroup"
                :items="selectedDepartment.classGroups"
                item-text="name"
                item-value="id"
                label="Group сонгоx"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-actions>
          <v-row>
            <v-col cols="12">
              <v-select
                return-object
                v-model="selectedRoom"
                :items="rooms"
                item-text="name"
                item-value="id"
                label="Ангийн дугаар"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-actions>
          <!-- <v-btn
            @click="_deleteLesson"
            class="btn-danger bg-gradient-danger"
            v-if="selectedDepartment && selectedClassGroup"
            >Устгаx</v-btn
          > -->
          <v-spacer></v-spacer>
          <v-btn
            @click="_saveClassGroupToTeacher"
            class="btn-primary bg-gradient-primary"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapState } from "vuex";
const fb = require("@/firebaseConfig.js");
import { sync } from "vuex-pathify";
export default {
  components: {},
  data: () => ({
    showEelj1: true,
    showEelj2: true,

    cellMatrix: null,
    weekDays: [
      { name: "Даваа", eeljIndex: 1 },
      { name: "Даваа", eeljIndex: 2 },
      { name: "Мягмар", eeljIndex: 1 },
      { name: "Мягмар", eeljIndex: 2 },
      { name: "Лхавга", eeljIndex: 1 },
      { name: "Лхавга", eeljIndex: 2 },
      { name: "Пүрэв", eeljIndex: 1 },
      { name: "Пүрэв", eeljIndex: 2 },
      { name: "Баасан", eeljIndex: 1 },
      { name: "Баасан", eeljIndex: 2 },
      // { name: "Бямба", dayIndex: 6 },
      // { name: "Ням", dayIndex: 7 },
    ],
    weekDaysNumber: 5,
    xeeljNumber: 7,
    selectedLesson: null,
    selectedRoom: null,
    rooms: [1, 2, 3, 4, 5],
    selectClassGroupDialog: false,
    selectedDepartment: null,
    selectedClassGroup: null,
    selectedTeacher: null,

    departmentSelected: 1,
    selectedSystemRoles: null,
    systemRoles: null,
    filterSelectedDepartment: null,

    departments: null,
    selected: [],
    newDialog: false,
    search: "",
    teachers: null,
    editedIndex: -1,
    editedItem: {
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      department: null,
    },
    defaultItem: {
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      department: null,
    },
    rules: {
      required: (value) => !!value || "Шаардлагатай.",
      emailRequired: (value) => !!value || "Gmail шаардлагатай.",
    },
  }),
  props: {
    title: String,
    description: String,
    addButtonTitle: String,
    childPath: String,

    path: {
      type: String,
      required: true,
    },
    directGo: {
      type: Boolean,
      default: false,
    },
    showAddButton: {
      type: Boolean,
      default: true,
    },
    goPparent: {
      type: Boolean,
      default: false,
    },
    goChild: {
      type: Boolean,
      default: false,
    },
    query: {
      type: Object,
      required: true,
    },
    headerNames: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...sync("*"),
    ...mapState(["userProfile", "currentUser"]),
    allCells() {
      var list = [];
      //var limit = this.weekDays2.length * this.xeeljNumber;
      // for (var xx = 1; xx <= limit; xx++) {
      //   for(var yy = 1; yy <= [1,2,3,4,5,6,7,8,9,10];yy+) {
      //     if(yy%2==0) {
      //       yy*7+1
      //     }
      //   }
      //     list.push(xx);
      // }

      // for(var yy = 1; yy<=70;yy+7) {
      //     if(yy%2==0) {
      //         list.push(yy)
      //     }
      //   }
      if (this.showEelj1) {
        for (var k = 1; k <= 70; k = k + 14) {
          for (var x = k; x < k + 7; x++) list.push(x);
        }
      }

      if (this.showEelj2) {
        for (var m = 8; m <= 70; m = m + 14) {
          for (var j = m; j < m + 7; j++) list.push(j);
        }
      }

      //for(var i=1; i<=limit; i + 7)
      return list;
    },
    headerNumbers() {
      var xxx = [];
      for (var k = 1; k <= this.weekDays2.length; k++) {
        for (var i = 1; i < 8; i++) {
          xxx.push({ tsag: i, eelj: k });
        }
      }
      return xxx;
    },

    weekDays2() {
      var list = [];

      if (this.showEelj1 && this.showEelj2) {
        list = this.weekDays;
      } else {
        if (this.showEelj1)
          list = this.weekDays.filter((item) => item.eeljIndex == 1);
        if (this.showEelj2)
          list = this.weekDays.filter((item) => item.eeljIndex == 2);
      }
      return list;
    },
    items() {
      var list = [];
      if (this.teachers != null) {
        for (var item of this.teachers) {
          item.avatar = require("@/assets/img/team-2.jpg");
          list.push(item);
        }
      }
      return list;
    },
    headers2() {
      return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    },
    teachers2() {
      var list = [];
      if (this.teachers != null) {
        var counter = 0;
        for (var item of this.teachers) {
          counter++;
          item.index = counter;
          item.avatar = require("@/assets/img/team-2.jpg");
          list.push(item);
        }
      }
      return list;
    },
    yyschool() {
      return fb.yyschool;
    },
  },
  created() {
    this._setupp();
    this.query.onSnapshot((querySnapshot) => {
      this.teachers = [];
      querySnapshot.forEach((doc) => {
        let teacher = doc.data();
        teacher.id = doc.id;
        teacher.ref = doc.ref;
        teacher.name2 =
          teacher.lastName && teacher.lastName.length > 0
            ? teacher.lastName[0] + "." + teacher.firstName
            : teacher.firstName;
        teacher.lessons = null;

        teacher.ref
          .collection("teacherCalendar-" + this.userData.school.currentYear)
          .onSnapshot((docs) => {
            teacher.lessons = [];
            docs.forEach((doc) => {
              let lesson = doc.data();
              lesson.id = doc.id;
              lesson.ref = doc.ref;
              teacher.lessons[lesson.cellNumber] = lesson;
            });
            console.log(teacher.lessons);
          });

        this.teachers.push(teacher);
      });
    });
    //this._getResults();
  },
  watch: {
    showEelj1(val) {
      console.log(val);
    },
    filterSelectedDepartment: function () {
      console.log(this.filterSelectedDepartment);
    },
  },
  methods: {
    _deleteLesson() {},
    _saveClassGroupToTeacher() {
      console.log(this.weekDaysNumber);
      console.log(
        this.selectedCellNumber,
        this.xeeljNumber,
        this.selectedCellNumber % this.xeeljNumber
      );

      var tsag =
        this.selectedCellNumber % this.xeeljNumber == 0
          ? 7
          : this.selectedCellNumber % this.xeeljNumber;
      var dayIndex = Math.ceil(
        Math.ceil(this.selectedCellNumber / this.xeeljNumber) / 2
      );
      console.log(dayIndex, tsag);

      if (this.selectedClassGroup) {
        this.selectedTeacher.ref
          .collection("teacherCalendar-" + this.selectedDepartment.startYear)
          .doc()
          .set({
            classGroupRef: this.selectedClassGroup.ref,
            classGroup: this.selectedClassGroup,
            cellNumber: this.selectedCellNumber,
            roomNumber: this.selectedRoom,
            departmentName: this.selectedDepartment.name,
            classGroupFullName: this.selectedClassGroup.fullName,
            lesson: this.selectedLesson,
            xeeljNumber: this.xeeljNumber,
          })
          .then(() => {
            this.selectClassGroupDialog = false;
            this.selectedTeacher = null;
            this.selectedCellNumber = null;
          });
      }
    },
    // _sumOfLessonOfTeacher(teacher){
    //   var sum = 0
    //   if(teacher.lessons){
    //     for(var xx of teacher.lessons){
    //       sum = sum + xx.
    //     }
    //   }
    // },
    _getTeacherLesson(teacher, cellNumber) {
      var xx = null;
      if (teacher.lessons) {
        for (const lesson of teacher.lessons) {
          if (lesson.cellNumber == cellNumber) {
            xx = lesson;
            break;
          }
        }
      }
      return xx;
    },
    _clickCell(teacher, cellNumber) {
      this.selectedTeacher = teacher;
      this.selectedCellNumber = cellNumber;
      this.selectedLesson = null;
      this.selectClassGroupDialog = true;
    },
    _setupp() {
      this.findDepartmentsAndClassGroups();
      this.userData.school.ref
        .collection("xDuties")
        .where("code", "==", "teacher")
        .orderBy("name", "asc")
        .onSnapshot((querySnapshot) => {
          this.systemRoles = [];
          querySnapshot.forEach((doc) => {
            let xDuty = doc.data();
            xDuty.id = doc.id;
            xDuty.ref = doc.ref;
            console.log(xDuty);
            this.systemRoles.push(xDuty);
          });
        });
      // fb.db
      //   .doc(this.yyschool)
      //   .collection("xuni-sectors")
      //   .orderBy("createdAt", "desc")
      //   .onSnapshot((querySnapshot) => {
      //     this.departments = [];
      //     querySnapshot.forEach((doc) => {
      //       let dep = doc.data();
      //       dep.id = doc.id;
      //       dep.ref = doc.ref;
      //       this.departments.push(dep);
      //     });
      //   });
    },
    findDepartmentsAndClassGroups() {
      this.userData.school.ref
        .collection("departments")
        .where("startYear", "==", this.userData.school.currentYear)
        .orderBy("index", "desc")
        .onSnapshot((querySnapshot) => {
          this.departments = [];
          querySnapshot.forEach((doc) => {
            let dep = doc.data();
            dep.id = doc.id;
            dep.ref = doc.ref;
            dep.classGroups = null;
            dep.ref
              .collection("programs")
              .orderBy("name", "asc")
              .get()
              .then((docs) => {
                dep.classGroups = [];
                docs.forEach((doc) => {
                  let cgroup = doc.data();
                  cgroup.id = doc.id;
                  cgroup.ref = doc.ref;
                  cgroup.name = cgroup.name.toUpperCase();
                  dep.classGroups.push(cgroup);
                });
                ///
                ///
              });
            this.departments.push(dep);
          });
        });
    },
    showAlert(a) {
      if (this.directGo == false) this._detail(a);
    },

    getColor(score) {
      if (score > 50) return "green";
      else if (score > 5) return "orange";
      else return "red";
    },
  },
};
</script>
 <style>
.scoreTables td {
  border: 1px solid #dddddd;
  text-align: left;
  height: 15px !important;
  padding: 0 !important;
}
.scoreTables th {
  border: 1px solid #dddddd;
  text-align: left;
  height: 15px !important;
  padding: 0 !important;
}
.departmentSelected {
  border: 2px dashed orange;
}
</style>