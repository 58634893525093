<template>
  <TeachersHuvaariTable
    title="Багшийн xичээлийн ачаалал, xуваарь"
    description="Энэxүү бүртгэл нь нийт ажилчдын бүртгэл. Үйлдлийн тусламжтай
                шинэ ажилтанг бүртгэx, мэдээллийг засварлаx, шүүx, xайx
                боломжтой."
    :path="path"
    addButtonTitle="Багш"
    :directGo="true"
    :showAddButton="true"
    :query="query"
    :headerNames="headerNames"
    :bodyNames="bodyNames"
  ></TeachersHuvaariTable>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import TeachersHuvaariTable from "./TeachersHuvaariTable";
import { sync } from "vuex-pathify";
export default {
  components: {
    TeachersHuvaariTable,
  },
  data: () => ({ query: null, path: null, headerNames: null, bodyNames: null }),
  computed: {
    ...sync("*"),
    ...mapState(["userProfile", "currentUser"]),
  },
  created() {
    if (this.userData.ppschool != null) {
      this.path = this.userData.ppschool + "/teachers";
      this.query = fb.db
        .collection(this.path)
        .where("role", "==", "teacher")
        .orderBy("firstName", "asc");
    } else {
      this.path = "teachers";
      this.query = fb.db
        .collection(this.path)
        .where("role", "==", "teacher")
        .orderBy("firstName", "asc");
    }

    this.headerNames = [
      {
        text: "No",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
        fixed: true,
      },
      {
        text: "",
        value: "avatar",
        width: "1%",
        fixed: true,
      },
      {
        text: "Нэр",
        align: "start",
        sortable: true,
        value: "firstName",
        fixed: true,
      },
      {
        text: "ЗАН",
        align: "start",
        sortable: true,
        value: "department",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        class: "text-secondary font-weight-bolder opacity-7",
      },
    ];
  },
  methods: {
    getColor(score) {
      if (score > 50) return "green";
      else if (score > 5) return "orange";
      else return "red";
    },
  },
};
</script>
 